<template>
    <div class="page">
        <Header />

        <h1>PC Refresh</h1>

        <h4>Keep your tech running with our simple service plans</h4>

        <div class = "pkg-container-small">
            <h1>Choose Your Membership:</h1>

            <div class = "pkg-small-flex">
                <div class = "pkg-description-col">
                    <div class = "pkg-details-header-row">
                        
                    </div>
                    <div class = "pkg-description-row" v-for="offer in offerings" :key="offer.id">
                        <div class = "pkg-description-title">{{offer.title}}</div>
                        <div class = "pkg-description-extra">{{offer.extra}}</div>
                    </div>
                </div>
                <div class = "pkg-details-col highlight-col">
                    <div class = "pkg-details-header-row">
                        <h3>PC Refresh Plus</h3>
                    </div>
                    <div class = "pkg-details-row" v-for="offer in offerings" :key="offer.id">
                        <div>{{offer.refreshplus ? "✔" : " "}}</div>
                    </div>
                </div>
                <div class = "pkg-details-col">
                    <div class = "pkg-details-header-row">
                        <h3>PC Refresh</h3>
                    </div>
                    <div class = "pkg-details-row" v-for="offer in offerings" :key="offer.id">
                        <div>{{offer.refresh ? "✔" : " "}}</div>
                    </div>
                </div>
            </div>

            <div>
                <div class = "radio-wrapper">
                    <input type="radio" id="one" value="Annually" v-model="billing">
                    <label for="one">Annually</label>
                </div>
                <div class = "radio-wrapper">
                    <input type="radio" id="two" value="Monthly" v-model="billing">
                    <label for="two">Monthly</label>
                </div>
            </div>

            <div>Pay Annually to save 20%</div>

            <div>
                <div>
                    <h3>PC Refresh Plus</h3>

                    <h1>
                        <s>{{billing == "Annually" ? "$" + pcrefreshpluscost.monthly : ""}}</s>
                        ${{ billing == "Annually" ? pcrefreshpluscost.annually : pcrefreshpluscost.monthly }} / Month
                    </h1>

                    <div v-if="billing == 'Annually'">${{pcrefreshpluscost.annualtotal}} billed annually</div>
                    <div v-if="billing == 'Monthly'">Monthly Plan, billed monthly</div>

                    <router-link :to="'/Appointment?type=refreshplus&service=' + billing + '&device=refreshplus'">Select Membership</router-link>
                </div>
                <div>
                    <h3>PC Refresh</h3>

                    <h1>
                        <s>{{billing == "Annually" ? "$" + pcrefreshcost.monthly : ""}}</s>
                        ${{ billing == "Annually" ? pcrefreshcost.annually : pcrefreshcost.monthly }} / Month
                    </h1>

                    <div v-if="billing == 'Annually'">${{pcrefreshcost.annualtotal}} billed annually</div>
                    <div v-if="billing == 'Monthly'">Monthly Plan, billed monthly</div>

                    <router-link :to="'/Appointment?type=refresh&service=' + billing + '&device=refreshplus'">Select Membership</router-link>
                </div>
            </div>
        </div>

        <div class = "pkg-container-large">
            <div class = "pkg-description-col">
                <div class = "pkg-description-header-row">
                    <h1>Choose your membership:</h1>

                    <div class = "radio-wrapper">
                        <input type="radio" id="one" value="Annually" v-model="billing">
                        <label for="one">Annually</label>
                        <label class = "savings" for="one"> - Save 20%</label>
                    </div>
                    <div class = "radio-wrapper">
                        <input type="radio" id="two" value="Monthly" v-model="billing">
                        <label for="two">Monthly</label>
                    </div>
                </div>
                <div class = "pkg-description-row" v-for="offer in offerings" :key="offer.id">
                    <div class = "pkg-description-title">{{offer.title}}</div>
                    <div class = "pkg-description-extra">{{offer.extra}}</div>
                </div>
            </div>
            <div class = "pkg-details-col highlight-col">
                <div class = "pkg-details-header-row">
                    <h3>PC Refresh Plus</h3>

                    <h1>
                        <s>{{billing == "Annually" ? "$" + pcrefreshpluscost.monthly : ""}}</s>
                        ${{ billing == "Annually" ? pcrefreshpluscost.annually : pcrefreshpluscost.monthly }} / Month
                    </h1>

                    <div v-if="billing == 'Annually'">${{pcrefreshpluscost.annualtotal}} billed annually</div>
                    <div v-if="billing == 'Monthly'">Monthly Plan, billed monthly</div>

                    <router-link :to="'/Appointment?type=refreshplus&service=' + billing + '&device=refreshplus'">Select Membership</router-link>
                </div>
                <div class = "pkg-details-row" v-for="offer in offerings" :key="offer.id">
                    <div>{{offer.refreshplus ? "✔" : " "}}</div>
                </div>
            </div>
            <div class = "pkg-details-col">
                <div class = "pkg-details-header-row">
                    <h3>PC Refresh</h3>

                    <h1>
                        <s>{{billing == "Annually" ? "$" + pcrefreshcost.monthly : ""}}</s>
                        ${{ billing == "Annually" ? pcrefreshcost.annually : pcrefreshcost.monthly }} / Month
                    </h1>

                    <div v-if="billing == 'Annually'">${{pcrefreshcost.annualtotal}} billed annually</div>
                    <div v-if="billing == 'Monthly'">Monthly Plan, billed monthly</div>

                    <router-link :to="'/Appointment?type=refresh&service=' + billing + '&device=refreshplus'">Select Membership</router-link>
                </div>
                <div class = "pkg-details-row" v-for="offer in offerings" :key="offer.id">
                    <div>{{offer.refresh ? "✔" : " "}}</div>
                </div>
            </div>
        </div>

        <div class = "extra-detail">
            Not satisfied with our service?  Cancel within 15 days of becoming a member and get your money back, no questions asked.
        </div>

        <div>

        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'PCRefresh',
  components:{
      Header,
      Footer
  },
    data : function(){
        return{
            api : "https://danstechservices.us:3000",
            billing : "Annually",
            pcrefreshcost : { annually : 12.00, monthly : 15.00, annualtotal : 144.00 },
            pcrefreshpluscost : { annually : 20.00, monthly : 25.00, annualtotal : 240.00 },
            offerings : [
                {id : 0, title : "$20 Off First Service", extra : "By Appointment - New Members Only", tooltip : "", refreshplus : true, refresh : true },
                {id : 1, title : "Monthly PC Tuneup", extra : "$75 Value - By Appointment", tooltip : "", refreshplus : true, refresh : true },
                {id : 2, title : "5% Off Parts Cost", extra : "Maximum $20 Per Transaction", tooltip : "", refreshplus : true, refresh : true },
                {id : 3, title : "Monthly Virus Scan and Cleanup", extra : "$75 Value - By Appointment", tooltip : "", refreshplus : true, refresh : false },
                {id : 4, title : "Free Home Pickup and Delivery", extra : "$20 value - Fuel Surcharge May Still Apply", tooltip : "", refreshplus : true, refresh : false },
                {id : 5, title : "No In Home Service Charge", extra : "$50 value", tooltip : "", refreshplus : true, refresh : false }
            ]
        }
    },
    mounted: function(){
        // fetch(this.api + "/api/getpcrefresh", { method: "POST" })
        // .then( res => res.json() )
        // .then( res => this.services = res.response );

        // fetch(this.api + "/api/getpcrefreshplus", { method: "POST" })
        // .then( res => res.json() )
        // .then( res => this.services = res.response );

        fetch(this.api + "/api/recordload", { method: "POST", headers : { userdata : 'pcrefresh'} });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
    display: block;
    text-decoration: none;
    color: #2c3e50;
    margin: 30px auto 30px auto;
    border-radius: 7px;
    padding: 20px;
    background-color: rgb(105, 236, 214);
    width: 80%;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    font-weight: bold;
}

a:hover{
    background-color: rgb(155, 187, 235);
}

.radio-wrapper{
    margin-left: 20%;
    text-align: left;
    width: 100%;
    font-size: 18px;
}

.page{
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #fff;
    background-color: #31a6f5;
    position: absolute;
    width: 100%;
    min-height: 100%;
    left: 0;
    top: 0;
}

.pkg-container-small{
    display: none;
}

.pkg-container-large{
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    background-color: rgb(250, 250, 250);
    color: #2c3e50;
    text-align: center;
    border-radius: 7px;
}

.pkg-small-flex{
    display: flex;
}

.pkg-description-col{
    width: 40%;
}

.pkg-details-col{
    width: 30%;
}

.pkg-description-header-row, .pkg-details-header-row{
    height: 250px;
}

.pkg-description-row, .pkg-details-row{
    height: 80px;
}

.pkg-description-row{
    margin-left: 20%;
    text-align: left;
}

.pkg-description-title{
    font-size: 18px;
    font-weight: bold;
}

.pkg-description-extra{
    font-size: 14px;
    color: #31a6f5;
}

.savings{
    font-weight: bold;
    color: #31a6f5;
}

.highlight-col{
    background-color: #d2edff;
}

.extra-detail{
    width: calc(80% - 40px);
    padding: 20px;
    margin: 20px auto 20px auto;
    background-color: #2c3e50;
    border-radius: 7px;
}

@media only screen and (max-width: 1180px) {
    .page{
        width: 100%;
    }
    a{
        display: block;
        text-decoration: none;
        color: #2c3e50;
        margin: 30px auto 30px auto;
        border-radius: 7px;
        padding: 20px 0 20px 0;
        background-color: rgb(105, 236, 214);
        width: 90%;
        cursor: pointer;
        transition: ease-in-out 0.2s;
        font-weight: bold;
    }

    .pkg-container-large{
        display: none;
    }

    .pkg-container-small{
        display: block;
        width: 100%;
        margin: auto;
        color: #31a6f5;
        background-color: rgb(250, 250, 250);
        padding-bottom: 10px;
    }

    .radio-wrapper{
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        display: inline-block;
        width: 50%;
        text-align: center;
    }

    .pkg-description-col{
        width: 54%;
    }

    .pkg-details-col{
        width: 23%;
    }

    .pkg-description-header-row, .pkg-details-header-row{
        height: 100px;
    }

    .pkg-description-row, .pkg-details-row{
        height: 120px;
    }
}
</style>
